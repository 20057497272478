<style scoped>
.ivu-form-item {
  margin-bottom: 5px !important;
}
</style>
<template>
  <!-- Main content -->
  <div>
    <Divider dashed>
      <span class="divider-text">新建公益客户</span>
    </Divider>
    <Form ref="customerData" :model="customerData" :rules="ruleValidate" label-position="top">
      <Row :gutter="8">
        <i-col :xs="24" :sm="24" :md="24" :lg="24">
          <FormItem label="客户名称" prop="name">
            <Input v-model="customerData.name" size="small" placeholder="请填写客户名称" />
          </FormItem>
        </i-col>
         <i-col :xs="24" :sm="24" :md="24" :lg="24">
          <FormItem label="客户类型" prop="name">
            <RadioGroup size="small" v-model="customerData.type" type="button">
              <Radio
                v-for="(item,index) in AdvertiserTypes"
                :label="item.id"
                :key="index"
              >{{item.name}}</Radio>
            </RadioGroup>
          </FormItem>
        </i-col>
        <i-col :xs="24" :sm="24" :md="24" :lg="24">
          <FormItem prop="tel" label="联系电话">
            <Input v-model="customerData.tel" size="small"  placeholder="请填写联系电话" />
          </FormItem>
        </i-col>
        <i-col span="24">
          <FormItem label="公司地址" style="width:100%" prop="provinceCode">
            <Row>
              <i-col :xs="24" :sm="24" :md="8" :lg="8">
                <FormItem style="width:95%">
                  <Select
                    v-model="customerData.provinceCode"
                    @on-change="handleSelectProvince"
                    clearable  size="small"
                    placeholder="省"
                  >
                    <Option
                      v-for="item in provinceList"
                      :value="item.code"
                      :key="item.code"
                    >{{item.name}}</Option>
                  </Select>
                </FormItem>
              </i-col>
              <i-col :xs="24" :sm="24" :md="8" :lg="8">
                <FormItem prop="cityCode" style="width:95%" >
                  <Select size="small"
                    v-model="customerData.cityCode"
                    @on-change="handleSelectCity"
                    clearable
                    placeholder="市"
                  >
                    <Option
                      v-for="item in cityList"
                      :value="item.code"
                      :key="item.code"
                    >{{item.name}}</Option>
                  </Select>
                </FormItem>
              </i-col>
              <i-col :xs="24" :sm="24" :md="8" :lg="8">
                <FormItem prop="areaCode" style="width:95%">
                  <Select v-model="customerData.areaCode" clearable placeholder="区"  size="small" >
                    <Option
                      v-for="item in regionList"
                      :value="item.code"
                      :key="item.code"
                    >{{item.name}}</Option>
                  </Select>
                </FormItem>
              </i-col>
            </Row>
          </FormItem>
        </i-col>
        <i-col span="24">
           <FormItem>
                  <i-input v-model.trim="customerData.address" placeholder="详细地址"  size="small" ></i-input>
                </FormItem>
        </i-col>
        <i-col span="24">
          <FormItem label="客户备注" prop="description">
            <Input
              v-model="customerData.description"
               size="small"
              placeholder="请填写客户备注信息"
            />
          </FormItem>
        </i-col>
        <i-col span="24">
          <FormItem label="联系人" prop="advertiserContactJson">
            <Row :gutter="8">
              <i-col
                v-for="(contact,contactindex) in contactList"
                :key="contactindex"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
              >
                <Card>
                  <div>
                    <div class="pull-right" style="float:right;">
                      <span type="pull-right" @click="confirmDeleteContact(contactindex)">
                        <Icon type="md-close" />
                      </span>
                    </div>
                    <br />
                    <div style="margin-left:15%">
                      <span>{{ contact.department }} {{contact.position}}</span>
                    </div>
                    <div style="margin-left:15%">
                      <span>{{ contact.name }}</span>
                    </div>
                    <div style="margin-left:15%">
                      <span>{{ contact.phone }}</span>
                    </div>
                  </div>
                </Card>
              </i-col>
              <i-col :xs="24" :sm="24" :md="24" :lg="24" style="margin-top:5px;">
                <Form
                  ref="contactData"
                  :model="contactData"
                  :rules="contactRuleValidate"
                  label-position="left"
                >
                  <Card>
                    <Row :gutter="8" >
                      <i-col span="12">
                        <FormItem prop="department">
                          <Input v-model="contactData.department" size="small" placeholder="部门" />
                        </FormItem>
                      </i-col>
                      <i-col span="12">
                        <FormItem prop="position">
                          <Input v-model="contactData.position" size="small" placeholder="职务" />
                        </FormItem>
                      </i-col>
                    </Row>
                    <Row :gutter="8" >
                      <i-col span="16">
                        <FormItem prop="name">
                          <Input v-model="contactData.name" size="small" placeholder="联系人姓名" />
                        </FormItem>
                      </i-col>
                    </Row>
                    <Row :gutter="8" >
                      <i-col span="16">
                        <FormItem prop="phone">
                          <Input v-model="contactData.phone" size="small" placeholder="联系电话" />
                        </FormItem>
                      </i-col>
                      <i-col span="8">
                        <FormItem>
                          <Button
                            type="success"
                            size="small"
                            @click="ConfirmAddContact('contactData')"
                          >新增联系人</Button>
                        </FormItem>
                      </i-col>
                    </Row>
                  </Card>
                </Form>
              </i-col>
            </Row>
          </FormItem>
        </i-col>
        <i-col span="24">
          <FormItem style="float:right;">

            <Button class="btn-black m-l-8" size="small" @click="handleReset('customerData')">重置</Button>
            <Button class="m-l-8" type="success" size="small" @click="handleSubmit('customerData')" >确认添加</Button>
          </FormItem>
        </i-col>
      </Row>
    </Form>
  </div>
</template>
<script>
import {
  getProvinceList,
  getCityListByProvinceCode,
  getRegionListByCityCode
} from '@/api/sys/provincecityregion'
import { addCommunityAdvertiser } from '@/api/crm/advertiser'

export default {
  name: 'CustomerCreate',
  data () {
    return {
      // AddContact: false,
      AdvertiserTypes: [
        { id: 4, name: '一类公益' },
        { id: 7, name: '二类公益' },
        { id: 8, name: '三类公益' }
      ],
      contactData: {
        name: '', // 联系人姓名
        position: '', // 职位
        department: '', // 部门
        phone: '' // 联系电话
      },
      contactRuleValidate: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        position: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        department: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: ' ', trigger: 'blur' }, // （支持手机号码，3-4位区号，7-8位直播号码，1－4位分机号）
          {
            pattern: /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/,
            message: ' '
          }
        ]
      },
      provinceList: [],
      cityList: [],
      regionList: [],
      contactList: [],
      industrydata: [],
      customerData: {
        userId: this.$store.getters.token.userInfo.userId,
        name: '',
        tel: '',
        areaCode: '',
        cityCode: '',
        type: 4,
        provinceCode: '',
        address: '',
        description: '',
        advertiserContactJson: ''
      },
      channellist: [],
      ruleValidate: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        cityCode: [
          { required: true, message: ' ' }
        ],
        provinceCode: [
          { required: true, message: ' ' }
        ],
        advertiserContactJson: [
          { required: true, message: '请至少添加一个联系人' }
        ]
      }
    }
  },
  methods: {
    getProvinceList () {
      getProvinceList().then((res) => {
        this.provinceList = res
      })
    },
    getCityList () {
      const data = {
        provinceCode: this.customerData.provinceCode
      }
      getCityListByProvinceCode(data).then((res) => {
        this.cityList = res
      })
    },
    getRegionList () {
      const data = {
        cityCode: this.customerData.cityCode
      }
      getRegionListByCityCode(data).then((res) => {
        this.regionList = res
      })
    },
    handleSelectProvince () {
      this.customerData.cityCode = null
      this.getCityList()
    },
    handleSelectCity () {
      this.customerData.areaCode = null
      this.getRegionList()
    },
    handleSubmit (dataname) {
      // this.$refs.contactData.resetFields()
      this.$refs[dataname].validate((valid) => {
        if (valid) {
          addCommunityAdvertiser(this.customerData).then((res) => {
            if (res) {
              this.$Notice.success({ desc: '添加公益客户成功！' })
              this.contactList = []
              this.$refs.customerData.resetFields()
              // this.$refs.contactData.resetFields()
              this.$emit('on-reloadList')
            }
          })
        }
      })
    },
    handleReset (name) {
      this.contactList = []
      this.$refs.customerData.resetFields()
      this.$refs.contactData.resetFields()
    },
    confirmDeleteContact (index) {
      this.contactList.splice(index, 1)
      this.reloadContact()
    },
    ConfirmAddContact (dataname) {
      this.$refs[dataname].validate((valid) => {
        if (valid) {
          this.contactList.push({
            name: this.contactData.name,
            department: this.contactData.department,
            position: this.contactData.position,
            phone: this.contactData.phone
          })
          this.reloadContact()
          this.$refs[dataname].resetFields()
        }
      })
    },
    reloadContact () {
      if (this.contactList.length > 0) {
        this.customerData.advertiserContactJson = JSON.stringify(
          this.contactList
        )
      } else {
        this.customerData.advertiserContactJson = ''
      }
    }
  },
  created () {
    this.getProvinceList()
  }
}
</script>
